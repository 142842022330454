import { Box } from '@mui/material'
import './App.scss'
import Header from './components/shared/Header/Header'
import Footer from './components/shared/Footer/Footer'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home/Home'
import { useEffect, useState } from 'react'
import { useAppDispatch } from './store/hooks'
import { setIsLumen } from './store/reducers/reducers'
import { clarity } from 'react-microsoft-clarity'
import Preloader from './components/shared/Preloader/Preloader'
import GenericBackground from './assets/images/background.png'
import LumenBackground from './assets/images/lumen/background.png'
import { useSelector } from 'react-redux'
import { RootState } from './store/store'

function App() {
	// Global variables
	const isLumen = useSelector(
		(state: RootState) => state.RootReducer.isLumenReducer.value
	)

	// Flag
	const [urlCheckDone, setURLCheckDone] = useState(false)

	// General
	const dispatch = useAppDispatch()

	useEffect(() => {
		// Clarity
		clarity.init(process.env.REACT_APP_MS_CLARITY_ID + '')

		// Check for URL
		if (!urlCheckDone) {
			setURLCheckDone(true)
			checkURLForStyling()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [urlCheckDone])

	// CHECK: Confirm URL of site to do Lumen or Generic styling
	const checkURLForStyling = () => {
		if (window.location.origin === process.env.REACT_APP_SM_LUMEN_URL + '') {
			dispatch(setIsLumen(true))
		}
	}

	return urlCheckDone ? (
		<>
			<Router>
				<Box
					component='main'
					sx={{
						backgroundImage: isLumen
							? `url('${LumenBackground}')`
							: `url('${GenericBackground}')`,
					}}>
					{/* Content */}
					<Box component='article'>
						{/* Header */}
						<Header />
						{/* Content - Service Manager installation */}
						<Routes>
							<Route path='/' element={<Home />} />
						</Routes>
					</Box>
					{/* Footer */}
					<Footer />
				</Box>
			</Router>
		</>
	) : (
		<Preloader />
	)
}

export default App
