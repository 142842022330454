import { Button, styled } from '@mui/material'
import { CustomPropForStyling } from '../utils/interfaces/ComponentModels'

export const CustomButton = styled(Button)<CustomPropForStyling>(
	({ lumen }) => ({
		padding: '10px 30px',
		backgroundColor: lumen === 'true' ? '#0075C9' : '#0d3c6a',
		color: '#fff',
		transition: '0.7s',
		'&:hover': {
			backgroundColor: lumen === 'true' ? '#0075C9' : '#0d3c6a',
			opacity: '0.6!important',
		},
		'& img': {
			width: '20px',
		},
	})
)
