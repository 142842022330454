import { BetaAPIMutation, BetaObject } from '../interfaces/APIModels'
import { AccountInfo } from '@azure/msal-browser'

export async function toAlphaString(betaText: string) {
	const alpha = process.env.REACT_APP_AES_ALPHA + ''
	const beta = process.env.REACT_APP_AES_BETA + ''
	const alphaMap = new Map<string, string>()

	for (let i = 0; i < beta.length; i++) {
		alphaMap.set(beta[i], alpha[i])
	}
	const alphaTextArray = Array.from(betaText, (betaChar) =>
		alphaMap.has(betaChar) ? alphaMap.get(betaChar)! : betaChar
	)
	const alphaText = alphaTextArray.join('')
	return alphaText
}

export function getEmptyAPIMutationObject() {
	var emptyBetaObj: BetaObject = {
		Content: '',
	}

	var emptyApiMutationObj: BetaAPIMutation = {
		BetaObject: emptyBetaObj,
		QueryParam: '',
	}

	return emptyApiMutationObj
}

export function getFormattedDate(): string {
	var today = new Date()
	var _year = today.getUTCFullYear() + ''
	var _month = today.getUTCMonth() + 1 + ''
	var _day = today.getUTCDate() + ''
	var _hours = today.getUTCHours() + ''
	var _minutes = today.getUTCMinutes() + ''
	var _seconds = today.getUTCSeconds() + ''

	if (_month.length < 2) {
		_month = '0' + _month
	}
	if (_day.length < 2) {
		_day = '0' + _day
	}
	if (_hours.length < 2) {
		_hours = '0' + _hours
	}
	if (_minutes.length < 2) {
		_minutes = '0' + _minutes
	}
	if (_seconds.length < 2) {
		_seconds = '0' + _seconds
	}

	var formattedDate = `${_year}-${_month}-${_day}T${_hours}:${_minutes}:${_seconds}`
	return formattedDate
}

export async function toBeta(obj: any) {
	var alphaText = JSON.stringify(obj)
	return toBetaString(alphaText)
}

export function toBetaString(alphaText: string) {
	const alpha = process.env.REACT_APP_AES_ALPHA + ''
	const beta = process.env.REACT_APP_AES_BETA + ''
	const betaMap = new Map<string, string>()

	for (let i = 0; i < alpha.length; i++) {
		betaMap.set(alpha[i], beta[i])
	}

	let betaText = ''
	for (let i = 0; i < alphaText.length; i++) {
		const alphaChar = alphaText[i]
		betaText += betaMap.has(alphaChar) ? betaMap.get(alphaChar)! : alphaChar
	}
	return betaText
}

export function numberToChar(num: number) {
	// 👇️ for Uppercase letters, replace `a` with `A`
	const code = 'A'.charCodeAt(0)
	return String.fromCharCode(code + num)
}

export function getUTCDateTimeNow() {
	var today = new Date()
	var utcToday = new Date(
		Date.UTC(
			today.getFullYear(),
			today.getMonth(),
			today.getDate(),
			today.getUTCHours(),
			today.getUTCMinutes(),
			today.getUTCSeconds()
		)
	)
	return utcToday
}

export function hasGraphScopes(scopes: string[]) {
	var scopeFound = false
	for (var i = 0; i < scopes.length; i++) {
		if (scopes[i].toLowerCase().includes('.default')) {
			scopeFound = true
		}
	}
	return scopeFound
}

export function emailValidation(email: string): boolean {
	const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
	return regex.test(email)
}

export function GetEmptyAccountInfo(): AccountInfo {
	var accInfo: AccountInfo = {
		environment: '',
		homeAccountId: '',
		localAccountId: '',
		tenantId: '',
		username: '',
		idToken: '',
		name: '',
		nativeAccountId: '',
	}
	return accInfo
}

export function timeout(delay: number) {
	return new Promise((res) => setTimeout(res, delay))
}
