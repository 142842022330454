import { PayloadAction, combineReducers, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

// Interfaces
interface AccessTokenState {
	value: string
}

// Used to check if site is Lumen
interface IsLumen {
	value: boolean
}

// Initial State
const initialAccessTokenState: AccessTokenState = {
	value: '',
}

const initialIsLumen: IsLumen = {
	value: false,
}

// Slices
export const AccessTokenSlice = createSlice({
	name: 'accessToken',
	initialState: initialAccessTokenState,
	reducers: {
		setAccessToken: (state, action: PayloadAction<string>) => {
			state.value = action.payload
		},
	},
})

export const IsLumenSlice = createSlice({
	name: 'isLumen',
	initialState: initialIsLumen,
	reducers: {
		setIsLumen: (state, action: PayloadAction<boolean>) => {
			state.value = action.payload
		},
	},
})

// Set actions
export const { setAccessToken } = AccessTokenSlice.actions
export const { setIsLumen } = IsLumenSlice.actions

// Get actions
export const getIsLumen = (state: RootState) =>
	state.RootReducer.isLumenReducer.value

// Reducers
var accessTokenReducer = AccessTokenSlice.reducer
var isLumenReducer = IsLumenSlice.reducer

const rootReducer = combineReducers({
	accessTokenReducer,
	isLumenReducer,
})

export default rootReducer
