import { usePostAndRetrieveDataMutation } from '../../../services/proxyAPIData'
import { toAlphaString } from '../../helperFunctions/helperFunctions'
import { DataResponse } from '../../interfaces/APIModels'
import ErrorLogging from '../ErrorLogging/ErrorLogging'

const PostAndRetrieveDataHook = () => {
	// Proxy call
	const [
		postAndRetrieveData,
		{ error: postAndRetrieveDataError, isLoading: postAndRetrieveDataLoading },
	] = usePostAndRetrieveDataMutation()

	// Error logging
	const { addErrorLog } = ErrorLogging()

	const postAndRetrieveDataFromDB = async (
		user: string,
		requestDescription: string,
		requestURL: string
	) => {
		// Error handling
		var isError = false
		var errorMessage = ''

		var dataResponse = {} as DataResponse

		return await postAndRetrieveData(requestURL)
			.unwrap()
			.then(async (response) => {
				if (response) {
					if (response.Content) {
						// Decrypt response
						dataResponse = JSON.parse(await toAlphaString(response?.Content))
						return dataResponse
					}
				}
			})
			.catch(async (error) => {
				if (error) {
					isError = true
					errorMessage = `An error occurred when returning ${requestDescription}`
				}
			})
			.finally(() => {
				if (isError && errorMessage.length > 0) {
					// Log to DB
					addErrorLog(requestDescription, requestURL, user)
				}
			})
	}

	return {
		postAndRetrieveDataFromDB,
		postAndRetrieveDataError,
		postAndRetrieveDataLoading,
	}
}

export default PostAndRetrieveDataHook
