import { v4 as uuidv4 } from 'uuid'
import { usePostBetaObjectWithoutRefetchMutation } from '../../../services/proxyAPIData'
import {
	getEmptyAPIMutationObject,
	getFormattedDate,
	toBetaString,
} from '../../helperFunctions/helperFunctions'
import { AddRequest, BetaObject } from '../../interfaces/APIModels'

const ErrorLogging = () => {
	// Post
	const [postWithoutRefetch] = usePostBetaObjectWithoutRefetchMutation()

	// This function adds an error to the DB. Passes in the action performed and error information
	const addErrorLog = async (
		actionPerformed: string,
		errorInfo: string,
		user: string
	) => {
		// Custom UUID and formatted data
		var requestID = uuidv4()
		var logTime = getFormattedDate()

		var log = {
			RequestID: requestID,
			ActionPerformed: `${process.env.REACT_APP_NAME}: ${actionPerformed}`,
			ErrorInfo: errorInfo,
			LogTime: logTime,
		}

		var errorLog = {
			Log: log,
		}

		var addLogObj: AddRequest = {
			Obj: errorLog,
			UserID: user,
			UserName: user,
		}

		var apiMutation = getEmptyAPIMutationObject()
		apiMutation.QueryParam = 'AddV2?Params=Log'
		var betaObj: BetaObject = {
			Content: await toBetaString(JSON.stringify(addLogObj)),
		}

		apiMutation.BetaObject = betaObj

		// post to DB
		await postWithoutRefetch(apiMutation).unwrap().catch()
	}

	return { addErrorLog }
}

export default ErrorLogging
