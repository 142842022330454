export enum ServiceTypes {
	MSTeamsDirectRouting = 1,
	Zoom = 2,
	UCMC = 3,
	MCCA = 4,
	BYOR = 5,
	BYOC = 6,
	BYOT = 7,
	BYPT = 8,
	DSIP = 9,
	SharedCarrier = 10,
	BYPC = 11,
	MSTeamsOperatorConnect = 12,
}
