import { Box } from '@mui/material'
import './Preloader.scss'

const Preloader = () => {
	return (
		<>
			<Box className='scene'>
				<Box className='cube-wrapper'>
					<Box className='cube'>
						<Box className='cube-faces'>
							<Box className='cube-face shadow'></Box>
							<Box className='cube-face bottom'></Box>
							<Box className='cube-face top'></Box>
							<Box className='cube-face left'></Box>
							<Box className='cube-face right'></Box>
							<Box className='cube-face back'></Box>
							<Box className='cube-face front'></Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default Preloader
