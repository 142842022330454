import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BetaAPIMutation, BetaObject } from '../utils/interfaces/APIModels'
import { RootState } from '../store/store'

const baseURL = process.env.REACT_APP_PROXY_FUNCTION_BASE_URL

export const proxyAPIData = createApi({
	reducerPath: 'proxyAPIData',
	baseQuery: fetchBaseQuery({
		prepareHeaders: (headers, { getState }) => {
			const accessToken = (getState() as RootState).RootReducer
				.accessTokenReducer.value
			headers.set(
				'x-functions-key',
				process.env.REACT_APP_API_PROXY_FUNCTION_KEY + ''
			)
			headers.append('authorization', accessToken)
			return headers
		},
		baseUrl: baseURL,
	}),
	refetchOnReconnect: true,

	endpoints: (build) => ({
		PostBetaObjectWithRefetch: build.mutation<
			BetaObject | undefined | null,
			BetaAPIMutation
		>({
			query: (apiMutation) => ({
				url: apiMutation.QueryParam,
				method: 'POST',
				body: apiMutation.BetaObject,
			}),
		}),

		PostBetaObjectWithoutRefetch: build.mutation<
			BetaObject | undefined | null,
			BetaAPIMutation
		>({
			query: (apiMutation) => ({
				url: apiMutation.QueryParam,
				method: 'POST',
				body: apiMutation.BetaObject,
			}),
		}),

		PostAndRetrieveData: build.mutation<BetaObject, string>({
			query: (queryParams) => ({
				url: `GetV2?Params=${queryParams}`,
				method: 'POST',
			}),
		}),

		GetProxyObject: build.query<BetaObject, string>({
			query: (queryParams) => `GetV2?Params=${queryParams}`,
		}),

		GetServiceManagementReportAndStatusForCustomer: build.mutation<
			string,
			string
		>({
			query: (customerID) =>
				`GetServiceManagementReportAndStatusForCustomer?customerID=${customerID}`,
		}),
	}),
})

export const {
	usePostBetaObjectWithRefetchMutation,
	usePostBetaObjectWithoutRefetchMutation,
	usePostAndRetrieveDataMutation,
	useGetProxyObjectQuery,
	useGetServiceManagementReportAndStatusForCustomerMutation,
} = proxyAPIData
