import { Box, Typography } from '@mui/material'

const Footer = () => {
	return (
		<>
			<Box component='footer'>
				{/* Text */}
				<Typography component='p'>
					© Copyright {new Date().getFullYear()}. All rights reserved.
				</Typography>
			</Box>
		</>
	)
}

export default Footer
