import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'

const Header = () => {
	// Global variable
	const isLumen = useSelector(
		(state: RootState) => state.RootReducer.isLumenReducer.value
	)

	return (
		<>
			<Box component='header'>
				{/* Header Logo */}
				<img
					src={
						isLumen
							? require('../../../assets/images/lumen/lumen-logo.png')
							: require('../../../assets/images/halo-logo.png')
					}
					alt='HALO Logo'
				/>
			</Box>
		</>
	)
}

export default Header
