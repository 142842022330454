import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import './SMInstall.scss'
import { GraphScopes } from '../../../utils/constants/constants'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { CustomButton } from '../../../styles/CustomButton'
import { useState } from 'react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { AccountInfo, PublicClientApplication } from '@azure/msal-browser'
import {
	getEmptyAPIMutationObject,
	timeout,
	toBetaString,
} from '../../../utils/helperFunctions/helperFunctions'
import PostAndRetrieveDataHook from '../../../utils/customHooks/APICalls/PostAndRetrieveDataHook'
import { MSTeamsUser } from '../../../utils/interfaces/DBModels'
import { ServiceTypes } from '../../../utils/enums/enums'
import { BetaObject } from '../../../utils/interfaces/APIModels'
import {
	useGetServiceManagementReportAndStatusForCustomerMutation,
	usePostBetaObjectWithoutRefetchMutation,
} from '../../../services/proxyAPIData'
import ErrorLogging from '../../../utils/customHooks/ErrorLogging/ErrorLogging'
import Preloader from '../../shared/Preloader/Preloader'

const SMInstall = () => {
	// Global variable
	const isLumen = useSelector(
		(state: RootState) => state.RootReducer.isLumenReducer.value
	)
	const smErrorHeaderText = 'Service Manager Installation failed'
	const { postAndRetrieveDataFromDB } = PostAndRetrieveDataHook()
	const [postWithoutRefetch] = usePostBetaObjectWithoutRefetchMutation()
	const [runSMChecksForCustomer] =
		useGetServiceManagementReportAndStatusForCustomerMutation()
	const { addErrorLog } = ErrorLogging()

	const [headerText, setHeaderText] = useState(
		'Sign in to configure Service Manager'
	)
	const [bodyText, setBodyText] = useState(
		'Sign in with a Microsoft Global Administrator account to install Service Manager'
	)

	const [showLoginButton, setShowLoginButton] = useState(true)
	const [showResetButton, setShowResetButton] = useState(false)
	const [showLoading, setShowLoading] = useState(false)

	function resetPage() {
		setHeaderText('Sign in to configure Service Manager')
		setBodyText(
			'Sign in with a Microsoft Global Administrator account to install Service Manager'
		)
		setShowLoginButton(true)
		setShowResetButton(false)
		setShowLoading(false)
	}

	const InstallServiceManager = async () => {
		setShowLoading(true)
		setShowLoginButton(false)
		var smErrorMessage = 'Consent to Service Manager App was unsuccessful.'

		var msalSMInstance = new PublicClientApplication({
			auth: {
				clientId: process.env.REACT_APP_SM_SETUP_CLIENT_ID + '',
			},
			system: {
				allowRedirectInIframe: true,
			},
		})

		await msalSMInstance.initialize()

		const SMApplogInRequest = {
			scopes: ['Organization.Read.All'],
			prompt: 'select_account',
		}

		await msalSMInstance
			.loginPopup(SMApplogInRequest)
			.then(async (authenticationResult) => {
				var accInfo = authenticationResult.account

				if (accInfo) {
					await msalSMInstance
						.logoutRedirect({
							onRedirectNavigate: () => {
								// Return false to stop navigation after local logout
								return false
							},
						})
						.then(async () => {
							setHeaderText('Initializing Service Manager Installation')
							setBodyText(
								'Please sign in to approve Service Manager Installer app'
							)

							var customerID = ''
							var smInstallErrorMessage =
								'Service Manager App install was unsuccessful.'
							var isInstallSuccessful = true

							var msalSMInstallInstance = new PublicClientApplication({
								auth: {
									clientId:
										process.env.REACT_APP_SM_INSTALL_ONLY_CLIENT_ID + '',
								},
								system: {
									allowRedirectInIframe: true,
								},
							})

							await msalSMInstallInstance.initialize()

							const SMAppInstallRequest = {
								scopes: GraphScopes,
								prompt: 'select_account',
							}

							await msalSMInstallInstance
								.loginPopup(SMAppInstallRequest)
								.then(async (authenticationResult) => {
									var accInfo = authenticationResult.account
									if (accInfo) {
										setHeaderText('Checking for your Teams Service...')
										setBodyText(
											'Checking for a Teams service that matches the account you have signed in with.'
										)

										customerID = await getCustomerIDFromMSTeams(accInfo)
										if (customerID.length > 0) {
											setHeaderText('Installing Service Manager')
											setBodyText(
												'Service Manager Installation has begun. This will take less than a minute.'
											)
											await timeout(20000)
											var _SMtoken =
												await msalSMInstallInstance.acquireTokenSilent({
													scopes: GraphScopes,
													account: accInfo,
												})

											if ((_SMtoken?.accessToken + '').length < 1) {
												isInstallSuccessful = false
											} else {
												var smInstallToken = _SMtoken?.accessToken + ''

												var teamsAdminServiceManagement = {
													CustomerID: customerID,
													GraphToken: smInstallToken,
												}

												var apiMutation = getEmptyAPIMutationObject()
												apiMutation.QueryParam =
													'AddTeamsAdminForServiceManagement'
												var betaObj: BetaObject = {
													Content: await toBetaString(
														JSON.stringify(teamsAdminServiceManagement)
													),
												}
												apiMutation.BetaObject = betaObj

												await postWithoutRefetch(apiMutation)
													.unwrap()
													.catch(async (error) => {
														if (error) {
															smErrorMessage =
																'An error occurred sending Teams Admin request for service manager'
															isInstallSuccessful = false
														}
													})
											}

											//log out
											await msalSMInstallInstance.logoutRedirect({
												onRedirectNavigate: () => {
													// Return false to stop navigation after local logout
													return false
												},
											})

											if (!isInstallSuccessful) {
												setHeaderText(smErrorHeaderText)
												setBodyText(smInstallErrorMessage)
												setShowLoading(false)
											} else {
												setHeaderText(
													'Service Manager Installation has completed'
												)
												setBodyText(
													'Service Manager results can take up to 8 hours to reflect. You may close this page.'
												)

												await runSMChecksForCustomer(
													toBetaString(customerID + '')
												)
													.unwrap()
													.catch(async (error) => {
														await addErrorLog(
															'HALO Service manager standalone installer get Latest SM Report and Status',
															`User ${
																accInfo.username
															},customer: ${customerID}. Failed to call endpoint to get Latest SM Report and Status: ${JSON.stringify(
																error
															)}`,
															accInfo?.username + ''
														)
													})
												setShowLoading(false)
											}
										} else {
											//log out
											await msalSMInstallInstance.logoutRedirect({
												onRedirectNavigate: () => {
													// Return false to stop navigation after local logout
													return false
												},
											})
											setShowResetButton(true)
											setShowLoading(false)
										}
									}
								})
								.catch(async (error) => {
									setShowResetButton(true)
									setHeaderText(smErrorHeaderText)
									setBodyText(smInstallErrorMessage)

									if (!(error?.errorMessage + '').includes('User cancelled')) {
										// Log to DB
										await addErrorLog(
											'HALO Service manager standalone installation and token generation',
											`User ${
												accInfo.username
											},customer: ${customerID}. SM install or token generation failure: ${JSON.stringify(
												error
											)}`,
											accInfo?.username + ''
										)
									}
									setShowLoading(false)
								})
						})
				}
			})
			.catch(async (error) => {
				setHeaderText(smErrorHeaderText)
				setBodyText(smErrorMessage)
				setShowResetButton(true)
				if (
					!(error?.errorMessage + '').includes('User cancelled') &&
					!(error?.errorMessage + '').includes('User denied')
				) {
					await addErrorLog(
						'HALO Service manager standalone installation consent only and token generation',
						`SM consent or token generation failure: ${JSON.stringify(error)}`,
						'No_User'
					)
				}
				setShowLoading(false)
			})
	}

	const getCustomerIDFromMSTeams = async (account: AccountInfo) => {
		var customerID = ''
		var noCustomerFoundError =
			'Installation cancelled. There was no customer found with the related tenant ID on your account. Please login with the correct account.'
		var dataResponse = await postAndRetrieveDataFromDB(
			account.username,
			'Get CustomerID from MSTeamsUser using TenantID',
			`MSTeamsUser.First(MSTeamsUser.TenantID = '${account.tenantId}' & MSTeamsUser.ServiceTypeID = '${ServiceTypes.MSTeamsDirectRouting}')`
		)

		if (dataResponse && Number(dataResponse.Count) > 0 && dataResponse.Obj) {
			var msTeamsResponse = dataResponse.Obj.MSTeamsUser as MSTeamsUser

			if (msTeamsResponse && msTeamsResponse.CustomerID) {
				// Set the customerID
				customerID = msTeamsResponse?.CustomerID + ''
			} else {
				// If no customerID, show error message
				setHeaderText(smErrorHeaderText)
				setBodyText(noCustomerFoundError)
			}
		} else {
			// If no customerID, show error message
			setHeaderText(smErrorHeaderText)
			setBodyText(noCustomerFoundError)
		}
		return customerID
	}

	return (
		<>
			<Box className='login-container'>
				{/* Header */}
				<Typography component='h1'>{headerText}</Typography>
				{/* Description */}
				<Typography component='p'>{bodyText}</Typography>

				{/* MSAL Login Button */}
				{showLoginButton && (
					<>
						<Tooltip title='Please ensure that pop-ups are enabled in your browser before proceeding'>
							<span>
								<IconButton>
									<WarningAmberIcon
										sx={{ color: 'orange', marginRight: '5px' }}
									/>
									<Typography component='p'>
										Browser pop-ups are required
									</Typography>
								</IconButton>
							</span>
						</Tooltip>
						<CustomButton
							lumen={isLumen.toString()}
							startIcon={
								<img
									src={require('../../../assets/icons/microsoft-logo.png')}
									alt='Microsoft Logo'
								/>
							}
							onClick={InstallServiceManager}>
							Login
						</CustomButton>
					</>
				)}

				{showResetButton && (
					<Button variant='contained' onClick={resetPage}>
						Reset
					</Button>
				)}

				{showLoading && <Preloader />}
			</Box>
		</>
	)
}

export default SMInstall
